<template>
  <textarea
    ref="textareaEl"
    v-model="model"
    :class="textareaClasses"
    :style="computedStyle"
    :rows="rows"
  />
</template>

<script setup lang="ts">
import type { FormFormatterFunc, FormVariant } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  variant?: FormVariant
  rows?: number | string
  maxRows?: number
  noResize?: boolean
  formatter?: FormFormatterFunc
}>(), {
  variant: 'light',
  rows: 3,
  formatter: (val: string) => val,
})

const model = defineModel<string>({
  set(value) {
    return props.formatter(value)
  },
})

watch(() => model.value, () => {
  nextTick(() => {
    maxHeight.value = calculateMaxHeight()
  })
})

const textareaClasses = computed(() => [
  'zform-textarea',
  `zform-textarea-${props.variant}`,
  {
    'zform-textarea-no-resize': props.noResize,
  },
])

const computedStyle = computed(() => {
  return { maxHeight: maxHeight.value }
})

const maxHeight = ref<string | undefined>(undefined)
const textareaEl = ref<HTMLTextAreaElement>()
function calculateMaxHeight() {
  if (props.maxRows === undefined) {
    return undefined
  }

  if (!textareaEl.value) return undefined
  const computedStyle = window.getComputedStyle(textareaEl.value)
  const border = toFloat(computedStyle.borderTopWidth, 0) + toFloat(computedStyle.borderBottomWidth, 0)
  const padding = toFloat(computedStyle.paddingTop, 0) + toFloat(computedStyle.paddingBottom, 0)
  const offset = border + padding
  const lineHeight = toFloat(computedStyle.lineHeight, 1)
  const rows = props.maxRows

  const maxHeight = lineHeight * rows + offset
  return `${maxHeight}px`
}
</script>
